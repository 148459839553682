import * as React from "react";
import { motion } from "framer-motion";
import * as styles from "./PageTransition.module.scss";
export const PageTransition: React.FC = ({
  children
}) => {
  return <motion.main key="page-transition" initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} exit={{
    opacity: 0
  }} transition={{
    type: "spring",
    mass: 0.45,
    stiffness: 160,
    transition: 2
  }}
  // style={{ originX: "0px", originY: "0px" }}
  >
      {children}
    </motion.main>;
};