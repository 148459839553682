import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import MouseFollower from "mouse-follower";
import gsap from "gsap";
import Navbar from "components/Navbar";
// import { Magnetic } from "components/animations";
// import Magnetic from "utils/magnetic";
import "styles/global.scss";
import * as styles from "./Layout.module.scss";
import { LanguageProvider } from "contexts/LanguageContext";
MouseFollower.registerGSAP(gsap);
const Layout: React.FC = ({
  location,
  children
}) => {
  const menuBtnRef = React.useRef<HTMLButtonElement>(null);
  const [cursor, setCursor] = React.useState(null);
  React.useEffect(() => {
    setCursor(new MouseFollower({
      stateDetection: {
        "-pointer": "a,button",
        "-hidden": "iframe"
      },
      stickDelta: 0.1
    }));
  }, []);
  React.useEffect(() => {
    if (cursor === null) return;
    cursor.removeStick();
    cursor.removeSkewing();
    cursor.removeState("-expand");
  }, [location, cursor]);
  return <LanguageProvider>
      <Navbar cursor={cursor} />
      <AnimatePresence mode="wait">{children}</AnimatePresence>
      <footer className={styles.footer}>
        <span>© 2024</span>
        <span>&nbsp;Designed &amp; Developed by&nbsp;</span>
        <a href="https://github.com/weebao" target="_blank" rel="noreferrer">
          Bao Dang
        </a>
      </footer>
    </LanguageProvider>;
};
export default Layout;