// extracted by mini-css-extract-plugin
export var Active = "Layout-module---active--b031f";
export var Exclusion = "Layout-module---exclusion--4351f";
export var Expand = "Layout-module---expand--3987f";
export var Hidden = "Layout-module---hidden--648ca";
export var Icon = "Layout-module---icon--2d15b";
export var Media = "Layout-module---media--28d0e";
export var Pointer = "Layout-module---pointer--8a7b7";
export var Text = "Layout-module---text--53b6c";
export var dark = "Layout-module--dark--94d93";
export var footer = "Layout-module--footer--cde73";
export var light = "Layout-module--light--f3fc7";
export var linkBtn = "Layout-module--link-btn--5a169";
export var mfCursor = "Layout-module--mf-cursor--28778";
export var mfCursorMedia = "Layout-module--mf-cursor-media--04f9b";
export var mfCursorMediaBox = "Layout-module--mf-cursor-media-box--8cb0b";
export var mfCursorText = "Layout-module--mf-cursor-text--d3745";