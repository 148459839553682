import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import { AnimatePresence } from "framer-motion";
import Layout from "components/Layout";
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props
}) => {
  return <Layout {...props}>{element}</Layout>;
};
export const onInitialClientRender = () => {
  document.getElementById("___gatsby").style.display = "none";
  setTimeout(() => {
    document.getElementById("___preloader").style.display = "none";
    document.getElementById("___gatsby").style.display = "block";
  }, 1000);
};