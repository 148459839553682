import * as React from "react";
import enData from "assets/languages/en.json";
import viData from "assets/languages/vi.json";
import jaData from "assets/languages/ja.json";
const langDataArr = [enData, viData, jaData];
const defaultState = {
  langIndex: 0,
  // 0 - English, 1 - Vietnamese, 2 - Japanese
  langData: langDataArr[0],
  changeLang: (lang: number) => {}
};
const LanguageContext = React.createContext(defaultState);
export const LanguageProvider: React.FC = ({
  children
}) => {
  const [langIndex, setLangIndex] = React.useState(0);
  const [langData, setLangData] = React.useState(langDataArr[0]);
  const changeLang = (lang: number) => {
    switch (lang) {
      case 0:
        setLangIndex(0);
        setLangData(langDataArr[0]);
        break;
      case 1:
        setLangIndex(1);
        setLangData(langDataArr[1]);
        break;
      case 2:
        setLangIndex(2);
        setLangData(langDataArr[2]);
        break;
      default:
        setLangIndex(0);
        setLangData(langDataArr[0]);
        break;
    }
  };
  return <LanguageContext.Provider value={{
    langIndex,
    langData,
    changeLang
  }}>
      {children}
    </LanguageContext.Provider>;
};
export default LanguageContext;