"use client";

import * as React from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import type { MagneticProps } from "./types.ts";
import * as styles from "./Magnetic.module.scss";
export const Magnetic: React.FC<MagneticProps> = ({
  speed = 1,
  offset = 1,
  spring = 0.3,
  gravity = 0,
  className,
  cursor,
  children,
  scrollRef
}) => {
  const childrenRef = React.useRef<HTMLElement>(null);

  // Handle the sticking cursor effect
  React.useEffect(() => {
    if (cursor === null) return;
    childrenRef.current.addEventListener("mouseenter", () => {
      cursor.setStick(childrenRef.current);
      cursor.setSkewing(2);
      cursor.addState("-expand");
    });
    childrenRef.current.addEventListener("mouseleave", () => {
      cursor.removeStick();
      cursor.removeSkewing();
      cursor.removeState("-expand");
    });
  }, [cursor]);

  // Handle scrolling (since the div is fixed in CSS)
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const show = gsap.fromTo(scrollRef.current, {
      y: 10
    }, {
      y: 0
    }).progress(1);
    ScrollTrigger.create({
      start: "top top",
      end: "max",
      onUpdate: self => {
        if (self.progress === 1) {
          show.play();
        } else {
          show.reverse();
        }
      }
    });
  }, [scrollRef]);

  // Handle the magnetic effect
  React.useEffect(() => {
    const xTo = gsap.quickTo(childrenRef.current, "x", {
      duration: speed,
      ease: `elastic.out(${offset}, ${spring})`
    });
    const yTo = gsap.quickTo(childrenRef.current, "y", {
      duration: speed,
      ease: `elastic.out(${offset}, ${spring})`
    });
    childrenRef.current.addEventListener("mousemove", e => {
      const {
        clientX,
        clientY
      } = e;
      const {
        height,
        width,
        left,
        top
      } = childrenRef.current.getBoundingClientRect();
      const x = clientX - (left + width / 2);
      const y = clientY - (top + height / 2);
      const gravityX = x * (gravity - 1);
      const gravityY = y * (gravity - 1);
      xTo(x + gravityX);
      yTo(y + gravityY);
    });
    childrenRef.current.addEventListener("mouseleave", () => {
      xTo(0);
      yTo(0);
    });
  }, [speed, offset, spring, gravity, children]);
  return <div className={`${styles.magnetic} ${className}`} ref={childrenRef}>
      {children}
    </div>;
};