import * as React from "react";
import LanguageContext from "contexts/LanguageContext";

export const useLanguageContext = () => {
  const context = React.useContext(LanguageContext);

  if (context === undefined) {
    throw new Error(
      "useLanguageContext is not being used within a LanguageProvider",
    );
  }

  return context;
};
