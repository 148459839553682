import * as React from "react";
import { motion } from "framer-motion";
import * as styles from "./AnimatedList.module.scss";
export const AnimatedList: React.FC = ({
  items
}) => {
  const containerVariants = {
    hidden: {
      opacity: 1
    },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };
  const itemVariants = {
    hidden: {
      y: 20,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "easeIn"
      }
    }
  };
  return <motion.ul key="list-container" variants={containerVariants} initial="hidden" whileInView="visible" viewport={{
    once: true
  }} className={styles.list}>
      {items.map((item, i) => <motion.li key={"list-items-" + i} variants={itemVariants}>
          {item}
        </motion.li>)}
    </motion.ul>;
};