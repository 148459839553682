import * as React from "react";
import { Link } from "gatsby";
import { block } from "million/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLanguageContext } from "hooks/useLanguageContext";
import { Magnetic, MotionLink } from "components/Animations";
import LogoIcon from "assets/images/logo-filled.svg";
import * as styles from "./Navbar.module.scss";
import type { NavbarProps } from "./types.ts";
const Navbar: React.FC<NavbarProps> = ({
  cursor
}) => {
  const {
    langIndex,
    langData,
    changeLang
  } = useLanguageContext();
  const hideableNavRef = React.useRef<HTMLDivElement>(null);
  const homeBtnRef = React.useRef<HTMLDivElement>(null);
  const projectsBtnRef = React.useRef<HTMLDivElement>(null);
  const contactBtnRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (cursor === null) return;
    const refs = [homeBtnRef, projectsBtnRef, contactBtnRef];

    // Make nav scrollable (since it is fixed in CSS)
    // gsap.registerPlugin(ScrollTrigger);
    // const navHeight = hideableNavRef.current.offsetHeight;
    // const windowHeight = window.innerHeight;
    // console.log(navHeight, windowHeight)
    // for (let i = 0; i < refs.length; i++) {
    //   gsap.to(refs[i].current, {
    //     scrollTrigger: {
    //       trigger: hideableNavRef.current,
    //       start: "top top",
    //       end: `+=${navHeight + windowHeight}px`,
    //       scrub: true,
    //     },
    //     y: () => -navHeight * 4,
    //   });
    // }
  });

  const pushRef = el => {
    if (menuBtnRef.current.length <= 3) {
      menuBtnRef.current.push(el);
    }
  };
  return <nav className={styles.layout}>
      <div className={styles.container} ref={hideableNavRef}>
        <div className={styles.alignLeft}>
          <Magnetic gravity={0.065} offset={1.1} speed={1.2} spring={0.2} className={styles.home} cursor={cursor} scrollRef={hideableNavRef}>
            <Link to="/">
              <div className={styles.linkBtn} ref={homeBtnRef}>
                <LogoIcon width={60} height={40} />
              </div>
            </Link>
          </Magnetic>
        </div>
        <div id="projects-btn" className={styles.alignRight}>
          <Magnetic gravity={0.065} offset={1.1} speed={1.2} spring={0.2} className={styles.projects} cursor={cursor} scrollRef={hideableNavRef}>
            <Link to={langData.navbar.projects.url}>
              <div className={styles.linkBtn} ref={projectsBtnRef}>
                {langData.navbar.projects.title}
              </div>
            </Link>
          </Magnetic>
        </div>
        {/* <div id="contact-btn" className={styles.alignRight}>
          <Magnetic
            gravity={0.065}
            offset={1.1}
            speed={1.2}
            spring={0.2}
            className={styles.contact}
            cursor={cursor}
            scrollRef={hideableNavRef}
          >
            <Link to={langData.navbar.contact.url}>
              <div className={styles.linkBtn} ref={contactBtnRef}>
                {langData.navbar.contact.title}
              </div>
            </Link>
          </Magnetic>
         </div> */}
        {/* <button onClick={() => toggleTheme(theme === "dark" ? "light" : "dark")}>
          {theme}
         </button> */}
      </div>
    </nav>;
};
export default Navbar;